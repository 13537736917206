import React from "react";
import { navigate } from "gatsby";

// External
import { RingLoader } from "react-spinners";
// Internal
import { supabase } from "../../helpers/supabase";
import TableV2 from "../TableV2";
import { getTagBgColor, getTagFontColor } from "../../helpers/globalHelpers";
import { fetchViews } from "../TableV2/helpers";

const FORM_ID = "outsideBrokerageAccount";
const FORM_TYPE = "complianceForms";

function getData() {
  // Fetches data from the database
  return supabase
    .rpc("submissions_with_metadata")
    .select("*")
    .eq("form_id", FORM_ID);
}

// data = array of objects of every Outside Brokerage Account form submission; the value of 'response' =  JSON stringified array of form responses
function processData(data) {
  const processedData = [];

  const statusMap = {
    approved: "Active",
    closed: "Closed",
    terminated: "Terminated",
    rejected: "Rejected",
    trails: "Trails",
    hold: "Hold",
    "in progress": "Pending",
    submitted: "Submitted",
    "changes requested": "Changes requested",
    modified: "Modified",
  };

  // iterate through each user submission; will need to push a row to processedData for each row of TableInput data
  // first add to userSubmission the data that is common to all rows of TableInput data
  data.forEach((row) => {
    const { submission_id, email, status, last_updated_time, form_id } = row;

    // need to parse the JSON stringified array of form responses twice
    const formResponses = JSON.parse(row.response);
    const tableInputRows = JSON.parse(formResponses[0]);

    // each row is a row of TableInput data
    tableInputRows.forEach((row) => {
      const processedRow = {
        // accessor: value to display,
        row_id: row.rowId,
        accountOwner: row.value["accountOwner"],
        accountNumber: row.value["accountNumber"],
        type: row.value["type"],
        firm: row.value["firm"],
        comments: row.value["comments"],
      };

      // combine each TableInput row data with the common data from userSubmission and push that object to processedData
      processedData.push({
        submission_id,
        email,
        status: status in statusMap ? statusMap[status] : status,
        last_updated_time,
        form_id,
        ...processedRow,
      });
    });
  });
  return processedData;
}

const OutsideBrokerageAccountTableNoMeta = ({ userInfo }) => {
  const userEmail = userInfo.email;

  const TABLE_ID = "outsideBrokerageAccount";

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    getData().then((response) => {
      const processedData = processData(response.data);
      setData(processedData);
      setLoading(false);
    });
  }, []);

  const [views, setViews] = React.useState({});
  const [viewsLoading, setViewsLoading] = React.useState(true);
  const [doViewRefresh, setDoViewRefresh] = React.useState(true);
  React.useEffect(() => {
    fetchViews(
      userEmail,
      TABLE_ID,
      setViews,
      setViewsLoading,
      setDoViewRefresh
    );
  }, [doViewRefresh]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Submission ID",
        accessor: "submission_id",
        keepInUpdate: true,
        isCalculated: true,
      },
      {
        Header: "Row ID",
        accessor: "row_id",
        idColumn: true,
        isCalculated: true,
      },
      {
        Header: "Rep Email",
        accessor: "email",
        isCalculated: true,
      },
      { Header: "Account Owner", accessor: "accountOwner", isCalculated: true },
      { Header: "Account #", accessor: "accountNumber", isCalculated: true },
      { Header: "Type", accessor: "type", isCalculated: true },
      { Header: "Brokerage Firm", accessor: "firm", isCalculated: true },
      { Header: "Comments", accessor: "comments", isCalculated: true },
      {
        Header: "Last Updated",
        accessor: "last_updated_time",
        isCalculated: true,
        columnType: "date",
        options: { displayDateTime: "true" },
      },
      {
        Header: "Status",
        accessor: "status",
        isCalculated: true,
        columnType: "enum",
        options: {
          values: [
            "Pending", // "in progress"
            "Submitted",
            "Changes requested",
            "Modified",
            "Active", // "approved"
            "Rejected",
            "Closed",
            "Terminated",
            "Trails",
            "Hold",
          ],
          className: "tag",
          bgColorMap: {
            Pending: getTagBgColor("Pending"),
            Submitted: getTagBgColor("Submitted"),
            "Changes requested": getTagBgColor("Changes requested"),
            Modified: getTagBgColor("Modified"),
            Active: getTagBgColor("Active"),
            Rejected: getTagBgColor("Rejected"),
            Closed: getTagBgColor("Closed"),
            Terminated: getTagBgColor("Terminated"),
            Trails: getTagBgColor("Trails"),
            Hold: getTagBgColor("Hold"),
          },
          fontColorMap: {
            Pending: getTagFontColor("Pending"),
            Submitted: getTagFontColor("Submitted"),
            "Changes requested": getTagFontColor("Changes requested"),
            Modified: getTagFontColor("Modified"),
            Active: getTagFontColor("Active"),
            Rejected: getTagFontColor("Rejected"),
            Closed: getTagFontColor("Closed"),
            Terminated: getTagFontColor("Terminated"),
            Trails: getTagFontColor("Trails"),
            Hold: getTagFontColor("Hold"),
          },
        },
      },
      {
        Header: "Form",
        accessor: "edit",
        Cell: ({ cell }) => {
          // const [formType, formId, submissionId, status] = value.split("|");
          const submissionId = cell.row.original.submission_id;
          return (
            <button
              class="button is-small"
              onClick={(event) => {
                // Prevent default form submission
                event.preventDefault();
                navigate(
                  `/editForm?formType=${FORM_TYPE}&formId=${FORM_ID}&submissionId=${submissionId}&prevPage=${window.location.pathname}`
                );
              }}
            >
              Edit
            </button>
          );
        },
      },
    ],
    []
  );

  const dataForTable = React.useMemo(() => data, [data]);

  if (loading || viewsLoading) {
    return <RingLoader />;
  } else {
    return (
      <TableV2
        userInfo={userInfo}
        data={dataForTable}
        columns={columns}
        views={views}
        defaultView="default"
        tableId={TABLE_ID}
        disableEdit={true}
        setDoViewRefresh={setDoViewRefresh}
      />
    );
  }
};

export default OutsideBrokerageAccountTableNoMeta;
