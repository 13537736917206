import React from "react";
import { navigate } from "gatsby";

// External
import { RingLoader } from "react-spinners";
// Internal
import { supabase } from "../../helpers/supabase";
import TableV2 from "../TableV2";
import { getTagBgColor, getTagFontColor } from "../../helpers/globalHelpers";
import { fetchViews } from "../TableV2/helpers";

const FORM_TYPE = "customerInformationForms";

function getData() {
  // Fetches data from the database
  return supabase
    .from("fact_form_submissions")
    .select("*")
    .in("form_id", ["clientInformationForm", "clientInformationFormMA"]);
}

function processData(data) {
  // Processes data to extract form response info
  return data.map((row) => {
    const formId = row.form_id;
    const responseData = JSON.parse(row.response);

    const statusMap = {
      approved: "Active",
      closed: "Closed",
      terminated: "Terminated",
      rejected: "Rejected",
      trails: "Trails",
      hold: "Hold",
      "in progress": "Pending",
      submitted: "Submitted",
      "changes requested": "Changes requested",
      modified: "Modified",
    };

    return {
      submission_id: row.submission_id,
      rep_email: row.email,
      dba: row.dba,
      entity_name:
        formId === "clientInformationForm" ? responseData[1] : responseData[2],
      engagement_description: responseData[10],
      engagement_type:
        (formId === "clientInformationFormMA" &&
          responseData[0] === "Capital Raise" &&
          "Capital Raise") ||
        (formId === "clientInformationFormMA" && "M&A") ||
        (responseData[0] === "Fund Sponsor Issuer" && "Fund Sponsor Raise") ||
        (responseData[0] === "Operating Company Issuer" && "Direct Raise"),
      city_state: responseData[4],
      website: responseData[9],
      fee_rate: responseData[22],
      retainer: responseData[23],
      transaction_amount:
        formId === "clientInformationForm"
          ? responseData[25]
          : responseData[24],
      transaction_fee:
        formId === "clientInformationForm"
          ? responseData[26]
          : responseData[25],
      engagement_status:
        row.status in statusMap ? statusMap[row.status] : row.status,
      last_updated: row.last_updated_time,
      form_id: formId,
    };
  });
}

const EngagementsTableNoMeta = ({ userInfo }) => {
  const userEmail = userInfo.email;

  const TABLE_ID = "engagementsNoMeta";

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    getData().then((response) => {
      setData(processData(response.data));
      setLoading(false);
    });
  }, []);

  const [views, setViews] = React.useState({});
  const [viewsLoading, setViewsLoading] = React.useState(true);
  const [doViewRefresh, setDoViewRefresh] = React.useState(true);
  React.useEffect(() => {
    fetchViews(
      userEmail,
      TABLE_ID,
      setViews,
      setViewsLoading,
      setDoViewRefresh
    );
  }, [doViewRefresh]);

  const meta = {
    afs: "true",
    ein: "true",
    ofac: "true",
    form_id: "clientInformationForm",
    ppm_lpa: "false",
    strategy: "Real Estate",
    sec_filings: null,
    sub_doc_psa: "true",
    engagement_id: 39,
    engagement_date: "4/20/21",
    termination_date: null,
    engagement_period: "30 days",
    teaser_fact_sheet: "false",
    marketing_deck_cim: "false",
    registration_status: "SEC",
    engagement_short_description:
      "Acquiring and operating producing assests in Permian Basin",
    beneficial_ownership_requirements: "CIP",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Submission ID",
        accessor: "submission_id",
        idColumn: true,
        isCalculated: true,
      },
      {
        Header: "Rep Email",
        accessor: "rep_email",
        isCalculated: true,
      },
      { Header: "Affiliate", accessor: "dba", isCalculated: true },
      { Header: "Client Name", accessor: "entity_name", isCalculated: true },
      {
        Header: "Engagement Description",
        accessor: "engagement_description",
        isCalculated: true,
      },
      {
        Header: "Engagement Type",
        accessor: "engagement_type",
        isCalculated: true,
      },
      {
        Header: "City/State",
        accessor: "city_state",
        isCalculated: true,
      },
      { Header: "Website", accessor: "website", isCalculated: true },
      { Header: "Fee Rate", accessor: "fee_rate", isCalculated: true },
      { Header: "Retainer", accessor: "retainer", isCalculated: true },
      {
        Header: "Transaction Amount",
        accessor: "transaction_amount",
        isCalculated: true,
        columnType: "currency",
      },
      {
        Header: "Transaction Fee",
        accessor: "transaction_fee",
        isCalculated: true,
        columnType: "currency",
      },
      {
        Header: "Status",
        accessor: "engagement_status",
        columnType: "enum",
        isCalculated: true,
        options: {
          values: [
            "Pending", // "in progress"
            "Submitted",
            "Changes requested",
            "Modified",
            "Active", // "approved"
            "Rejected",
            "Closed",
            "Terminated",
            "Trails",
            "Hold",
          ],
          className: "tag",
          bgColorMap: {
            Pending: getTagBgColor("Pending"),
            Submitted: getTagBgColor("Submitted"),
            "Changes requested": getTagBgColor("Changes requested"),
            Modified: getTagBgColor("Modified"),
            Active: getTagBgColor("Active"),
            Trails: getTagBgColor("Trails"),
            Rejected: getTagBgColor("Rejected"),
            Closed: getTagBgColor("Closed"),
            Terminated: getTagBgColor("Terminated"),
            Hold: getTagBgColor("Hold"),
          },
          fontColorMap: {
            Pending: getTagFontColor("Pending"),
            Submitted: getTagFontColor("Submitted"),
            "Changes requested": getTagFontColor("Changes requested"),
            Modified: getTagFontColor("Modified"),
            Active: getTagFontColor("Active"),
            Trails: getTagFontColor("Trails"),
            Rejected: getTagFontColor("Rejected"),
            Closed: getTagFontColor("Closed"),
            Terminated: getTagFontColor("Terminated"),
            Hold: getTagFontColor("Hold"),
          },
        },
      },
      {
        Header: "Last Updated",
        accessor: "last_updated",
        isCalculated: true,
        columnType: "date",
        options: { displayDateTime: "true" },
      },
      {
        Header: "Form ID",
        accessor: "form_id",
        isCalculated: true,
        keepInUpdate: true,
      },
      {
        Header: "Form",
        accessor: "edit",
        Cell: ({ cell }) => {
          const submissionId = cell.row.original.submission_id;
          const FORM_ID = cell.row.original.form_id;
          return (
            <button
              class="button is-small"
              onClick={(event) => {
                // Prevent default form submission
                event.preventDefault();
                navigate(
                  `/editForm?formType=${FORM_TYPE}&formId=${FORM_ID}&submissionId=${submissionId}&prevPage=${window.location.pathname}`
                );
              }}
            >
              Edit
            </button>
          );
        },
      },
    ],
    []
  );

  const dataForTable = React.useMemo(() => data, [data]);

  if (loading || viewsLoading) {
    return <RingLoader />;
  } else {
    return (
      <TableV2
        userInfo={userInfo}
        data={dataForTable}
        columns={columns}
        views={views}
        defaultView="default"
        tableId={TABLE_ID}
        disableEdit={true}
        setDoViewRefresh={setDoViewRefresh}
      />
    );
  }
};

export default EngagementsTableNoMeta;
