import React from "react";
import Layout from "../components/layout";
import { navigate } from "gatsby";

import { AuthProvider, useAuth } from "../context/auth";
import EngagementsTableNoMeta from "../components/DataTables/EngagementsTableNoMeta";
import InvestorsTable from "../components/DataTables/InvestorsTable";
import PstTable from "../components/DataTables/PstTable";
import ObaTable from "../components/DataTables/ObaTable";
import OutsideBrokerageAccountTableNoMeta from "../components/DataTables/OutsideBrokerageAccountTableNoMeta";
import PoliticalContributionsTable from "../components/DataTables/PoliticalContributionsTable";
import CyberSecurityTable from "../components/DataTables/CyberSecurityTable";

const Tables = () => {
  const { user, dba, admin } = useAuth();
  const userEmail = user?.email;
  const userInfo = {
    email: userEmail,
    dba,
    admin,
  };

  const [value, setValue] = React.useState("Engagements");

  const tables = {
    Engagements: <EngagementsTableNoMeta userInfo={userInfo} />,
    Investors: <InvestorsTable userInfo={userInfo} />,
    "Private Securities Transaction": <PstTable userInfo={userInfo} />,
    "Outside Business Activity": <ObaTable userInfo={userInfo} />,
    "Outside Brokerage Account": (
      <OutsideBrokerageAccountTableNoMeta userInfo={userInfo} />
    ),
    "Political Contributions & Gifts": (
      <PoliticalContributionsTable userInfo={userInfo} />
    ),
    "Cyber Security Questionaire": <CyberSecurityTable userInfo={userInfo} />,
  };

  function handleChange(event) {
    setValue(event.target.value);
  }

  if (user && dba && (admin || user.email === "dvick@aspenpartners.com")) {
    return (
      <Layout pageName="Tables">
        <div class="select is-small">
          <select value={value} onChange={handleChange}>
            {Object.keys(tables).map((key) => (
              <option value={key}>{key}</option>
            ))}
          </select>
        </div>
        <div style={{ paddingTop: "1rem" }}>
          <p class="title">{value}</p>
          {tables[value]}
        </div>
      </Layout>
    );
  } else if (
    user &&
    (dba === "EB Exchange" ||
      dba === "Green Front" ||
      dba === "Twin Bay Partners")
  ) {
    return (
      <Layout pageName="Tables">
        <div class="select is-small">
          <select value={value} onChange={handleChange}>
            {Object.keys(tables).map((key) => (
              <option value={key}>{key}</option>
            ))}
          </select>
        </div>
        <div style={{ paddingTop: "1rem" }}>
          <p class="title">{value}</p>
          {tables[value]}
        </div>
      </Layout>
    );
  } else if (user && dba) {
    return (
      <Layout pageName="Tables">
        <p>Coming soon.</p>
        {/* <div class="select is-small">
          <select
            value={value}
            onChange={handleChange}
          >
            {Object.keys(tables).map((key) => (
              <option value={key}>{key}</option>
            ))}
          </select>
        </div>
        <div style={{ paddingTop: "1rem" }}>
          <p class="title">{value}</p>
          {tables[value]}
        </div> */}
      </Layout>
    );
  } else if (user) {
    return (
      <Layout pageName="Tables">
        <p class="title">Frontier Compliance Portal</p>
        <p>
          Please contact your administrator. You are not yet associated with a
          DBA.
        </p>
      </Layout>
    );
  } else {
    navigate("/login");
    return <div />;
  }
};

const TablesPage = () => {
  return (
    <AuthProvider>
      <Tables />
    </AuthProvider>
  );
};

export default TablesPage;
